import { Display, SizeCollection } from "../../base/Text/Text.map";

export type FontStyle = "default" | "alternative" | "title" | "body";

export type FontStyleMap = {
  [key in FontStyle]: {
    display: Display,
    case: string,
    size: SizeCollection,
  }
};

export const fontStyleMap: FontStyleMap = {
  default: {
    display: "subtitle",
    case: "normal-case",
    size: "titleSm",
  },
  alternative: {
    display: "subtitle",
    case: "uppercase",
    size: "titleXs",
  },
  title: {
    display: "subtitle",
    case: "normal-case first-letter:uppercase",
    size: "titleMd",
  },
  body: {
    display: "default",
    case: "normal-case",
    size: "bodySm",
  },
};
