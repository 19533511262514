import { FC, ReactNode } from "react";

import Text from "apps/website/components/base/Text/Text";
import {
  Align,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import Spacer from "../../layout/Spacer/Spacer";

import { FontStyle, fontStyleMap } from "./Label.map";

export type TLabelLayout = "default" | "secondary";

export interface ILabel {
  label: string;
  align?: Align;
  hideLabel?: boolean;
  optional?: boolean;
  UUID: string;
  className?: string;
  fontStyle?: FontStyle;
  children?: ReactNode;
  layout?: TLabelLayout;
}

const Label: FC<ILabel> = ({ label, hideLabel = true, optional = false, UUID, className, fontStyle = "default", children, align = "default", layout = "default" }) => (
  <label data-component="Label" htmlFor={UUID} className={`w-full ${className}`}>
    { align === "center" && (
      <Spacer size="lg" />
    ) }
    { layout === "secondary" ? (
      <>
        <div className="flex items-center mb-1">
          { !optional && (<Text tag="span" color="required" display="subtitle" className="mr-1">*</Text>) }
          <Text
            tag="span"
            size={legacySizeCollectionMap[fontStyleMap[fontStyle].size]}
            align={align}
            display={fontStyleMap[fontStyle].display}
            className={`block ${fontStyleMap[fontStyle].case} ${hideLabel && "sr-only"}`}
          >
            { label }
          </Text>
          <Text tag="span" size="xs" display="subtitle" className="ml-1 text-[var(--color-text-charcoal)]">({ optional ? "optional" : "required" })</Text>
        </div>
      </>
    ) : (
      <Text
        tag="span"
        size={legacySizeCollectionMap[fontStyleMap[fontStyle].size]}
        align={align}
        display={fontStyleMap[fontStyle].display}
        className={`mb-1 block ${fontStyleMap[fontStyle].case} ${hideLabel && "sr-only"}`}
      >
        { !optional && <span className="text-red">*</span> }{ label }
      </Text>
    ) }
    { align === "center" && (
      <Spacer size="lg" />
    ) }
    { children }
  </label>
);

export default Label;
